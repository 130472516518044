/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-04-28 08:58:36
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-12 16:24:17
 */

import echarts from "echarts";

const commonYconfig = {
  type: "value",
  axisLabel: {
    formatter: "{value}",
    textStyle: {
      color: "rgba(94, 98, 102, 1)",
      fontSize: 14
    }
  },
  nameTextStyle: {
    color: "rgba(51, 51, 51, 1)",
    fontSize: 14
  },
  axisLine: {
    lineStyle: {
      color: "rgba(238, 238, 238, 1)"
    }
  },
  axisTick: {
    show: false
  },
  splitLine: {
    show: false
  },
  minorTick: {
    splitNumber: 5
  }
};

const commonXconfig = {
  type: "category",
  boundaryGap: true,
  axisLabel: {
    color: "rgba(94, 98, 102, 1)",
    fontSize: 14
  },
  axisLine: {
    show: true,
    lineStyle: {
      color: "rgba(238, 238, 238, 1)"
    }
  },
  axisTick: {
    show: false
  },
  splitLine: {
    show: false
  }
};

const gridConfig = {
  left: "2.5%",
  right: "1%",
  top: "20%",
  bottom: "2%",
  containLabel: true
};

export const serviceOptions = {
  grid: { ...gridConfig },
  tooltip: {
    show: true,
    trigger: "axis",
    axisPointer: {
      lineStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(65, 210, 140, 0)" // 0% 处的颜色
            },
            {
              offset: 0.5,
              color: "rgba(65, 210, 140, 1)" // 100% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(65, 210, 140, 0)" // 100% 处的颜色
            }
          ],
          global: false // 缺省为 false
        }
      },
      label: {
        show: false
      }
    },
    formatter: p => {
      let dom = `<div>
      <div style="font-size:14px;color: rgba(243, 37, 78, 1);margin-bottom: 8px;">${p[1].seriesName}: ${p[1].data}</div>
      <div style="font-size:14px;color: rgba(48, 114, 246, 1);margin-bottom: 8px;">${p[2].seriesName}: ${p[2].data}</div>
      <div style="font-size:14px;color: rgba(48, 114, 246, 1);margin-bottom: 8px;">${p[3].seriesName}: ${p[3].data}</div>
      <div style="font-size:14px;color: rgba(19, 193, 153, 1);margin-bottom: 8px;">${p[4].seriesName}: ${p[4].data}</div>
      <div style="font-size:14px;color: rgba(255, 86, 20, 1);">${p[5].seriesName}: ${p[5].data}</div>
  </div>`;
      return dom;
    },
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: "rgba(238, 238, 238, 1)",
    padding: 20,
    borderWidth: 1,
    shadowStyle: {
      width: "auto", // 阴影大小
      color: "rgba(0,0,0,0)", // 阴影颜色
      opacity: 0
    },
    textStyle: {
      fontSize: 14
    }
  },
  legend: {
    show: true,
    x: "right",
    y: "35",
    top: "3%",
    itemWidth: 10,
    itemHeight: 10,
    itemStyle: {
      color: "rgba(65, 210, 140, 1)"
    },
    textStyle: {
      color: "rgba(51, 51, 51, 1)",
      fontSize: 14
    },
    data: ["订单数"]
  },
  xAxis: [
    {
      data: [],
      ...commonXconfig
    }
  ],
  yAxis: [
    {
      name: "总订单数(个)",
      ...commonYconfig
    }
  ],
  series: [
    {
      name: "订单数",
      type: "line",
      symbol: "circle",
      symbolSize: 8,
      yAxisIndex: 0,
      smooth: true,
      lineStyle: {
        show: false,
        color: "rgba(65, 210, 140, 1)",
        width: 2
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0,
            color: "rgba(65, 210, 140, 0)"
          },
          {
            offset: 1,
            color: "rgba(65, 210, 140, 0.1)"
          }
        ])
      },
      itemStyle: {
        normal: {
          color: "rgba(255, 255, 255, 1)",
          borderWidth: 3,
          borderColor: "rgba(246, 169, 65, 1)",
          opacity: 0
        },
        emphasis: {
          opacity: 0
        }
      },
      markPoint: {
        symbol: "arrow",
        symbolSize: 0
      },
      data: []
    },
    {
      name: "待签收",
      type: "line",
      symbol: "circle",
      symbolSize: 8,
      yAxisIndex: 0,
      smooth: true,
      lineStyle: {
        show: false,
        color: "rgba(65, 210, 140, 1)",
        width: 0
      },
      areaStyle: {
        opacity: 0,
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0,
            color: "rgba(65, 210, 140, 0)"
          },
          {
            offset: 1,
            color: "rgba(65, 210, 140, 0.1)"
          }
        ])
      },
      itemStyle: {
        normal: {
          color: "rgba(255, 255, 255, 1)",
          borderWidth: 3,
          borderColor: "rgba(246, 169, 65, 1)",
          opacity: 0
        },
        emphasis: {
          opacity: 0
        }
      },
      markPoint: {
        symbol: "arrow",
        symbolSize: 0
      },
      data: []
    },
    {
      name: "服务中",
      type: "line",
      symbol: "circle",
      symbolSize: 8,
      yAxisIndex: 0,
      smooth: true,
      lineStyle: {
        show: false,
        color: "rgba(65, 210, 140, 1)",
        width: 0
      },
      areaStyle: {
        opacity: 0,
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0,
            color: "rgba(65, 210, 140, 0)"
          },
          {
            offset: 1,
            color: "rgba(65, 210, 140, 0.1)"
          }
        ])
      },
      itemStyle: {
        normal: {
          color: "rgba(255, 255, 255, 1)",
          borderWidth: 3,
          borderColor: "rgba(246, 169, 65, 1)",
          opacity: 0
        },
        emphasis: {
          opacity: 0
        }
      },
      markPoint: {
        symbol: "arrow",
        symbolSize: 0
      },
      data: []
    },
    {
      name: "已预约",
      type: "line",
      symbol: "circle",
      symbolSize: 8,
      yAxisIndex: 0,
      smooth: true,
      lineStyle: {
        show: false,
        color: "rgba(65, 210, 140, 1)",
        width: 0
      },
      areaStyle: {
        opacity: 0,
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0,
            color: "rgba(65, 210, 140, 0)"
          },
          {
            offset: 1,
            color: "rgba(65, 210, 140, 0.1)"
          }
        ])
      },
      itemStyle: {
        normal: {
          color: "rgba(255, 255, 255, 1)",
          borderWidth: 3,
          borderColor: "rgba(246, 169, 65, 1)",
          opacity: 0
        },
        emphasis: {
          opacity: 0
        }
      },
      markPoint: {
        symbol: "arrow",
        symbolSize: 0
      },
      data: []
    },
    {
      name: "已完成",
      type: "line",
      symbol: "circle",
      symbolSize: 8,
      yAxisIndex: 0,
      smooth: true,
      lineStyle: {
        show: false,
        color: "rgba(65, 210, 140, 1)",
        width: 0
      },
      areaStyle: {
        opacity: 0,
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0,
            color: "rgba(65, 210, 140, 0)"
          },
          {
            offset: 1,
            color: "rgba(65, 210, 140, 0.1)"
          }
        ])
      },
      itemStyle: {
        normal: {
          color: "rgba(255, 255, 255, 1)",
          borderWidth: 3,
          borderColor: "rgba(246, 169, 65, 1)",
          opacity: 0
        },
        emphasis: {
          opacity: 0
        }
      },
      markPoint: {
        symbol: "arrow",
        symbolSize: 0
      },
      data: []
    },
    {
      name: "已结算",
      type: "line",
      symbol: "circle",
      symbolSize: 8,
      yAxisIndex: 0,
      smooth: true,
      lineStyle: {
        show: false,
        color: "rgba(65, 210, 140, 1)",
        width: 0
      },
      areaStyle: {
        opacity: 0,
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0,
            color: "rgba(65, 210, 140, 0)"
          },
          {
            offset: 1,
            color: "rgba(65, 210, 140, 0.1)"
          }
        ])
      },
      itemStyle: {
        normal: {
          color: "rgba(255, 255, 255, 1)",
          borderWidth: 3,
          borderColor: "rgba(246, 169, 65, 1)",
          opacity: 0
        },
        emphasis: {
          opacity: 0
        }
      },
      markPoint: {
        symbol: "arrow",
        symbolSize: 0
      },
      data: []
    }
  ]
};
