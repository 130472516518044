<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-03 14:21:23
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-11 18:14:09
-->
<template>
  <div class="install-statistical">
    <div class="personal-center-title">
      订单统计
    </div>
    <div class="numerical">
      <!-- 16.5 -->
      <div class="item" v-for="(item, i) in data" :key="i">
        <div class="tilte">{{ item.title }}</div>
        <div class="count text-ellipsis" :title="item.count">
          {{ item.count }}
        </div>
      </div>
    </div>
    <div ref="service-charts" class="charts-div vehicle-charts"></div>
    <div class="date-picker">
      <el-date-picker
        v-model="date"
        :append-to-body="false"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleChangeDate"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import { serviceOptions } from "./charts-config";
import moment from "moment";
export default {
  data() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);

    return {
      date: [start, end],
      serviceInstance: null,
      data: [
        {
          title: "总订单数",
          count: 0
        },
        {
          title: "待签收",
          count: 0
        },
        {
          title: "服务中",
          count: 0
        },
        {
          title: "已预约",
          count: 0
        },
        {
          title: "已完成",
          count: 0
        },
        {
          title: "已结算",
          count: 0
        }
      ]
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.serviceInstance = this.$echarts.initCharts(
      this.totalInstance,
      this.$refs["service-charts"],
      serviceOptions
    );
    this.installOrderStatistics();
    this.installOrderData();
  },
  methods: {
    installOrderStatistics() {
      this.$api.installOrderStatistics({ userId: this.userId }).then(res => {
        this.data = [
          {
            title: "总订单数",
            count: res.data.allNum
          },
          {
            title: "待签收",
            count: res.data.receivingNum
          },
          {
            title: "服务中",
            count: res.data.serviceNum
          },
          {
            title: "已预约",
            count: res.data.makeNum
          },
          {
            title: "已完成",
            count: res.data.completeNum
          },
          {
            title: "已结算",
            count: res.data.settlementNum
          }
        ];
      });
    },
    installOrderData() {
      let date = this.date;
      let params = {
        timeE: moment(date[1]).format("YYYY-MM-DD"),
        timeS: moment(date[0]).format("YYYY-MM-DD"),
        userId: this.userId
      };
      this.$api.installOrderData(params).then(res => {
        let allNums = []; // 总订单数
        let receivingNums = []; //待签收
        let serviceNums = []; // 服务中
        let makeNums = []; //已预约
        let completeNums = []; // 已完成
        let settlementNums = []; //已结算
        let xData = []; // x轴数据
        let data = res.data;
        data.map(item => {
          xData.push(moment(item.date).format("MM.DD"));
          allNums.push(item.allNum || 0);
          receivingNums.push(item.receivingNum || 0);
          serviceNums.push(item.serviceNum || 0);
          makeNums.push(item.makeNum || 0);
          completeNums.push(item.completeNum || 0);
          settlementNums.push(item.settlementNum || 0);
        });
        serviceOptions.xAxis[0].data = xData;
        serviceOptions.series[0].data = allNums;
        serviceOptions.series[1].data = receivingNums;
        serviceOptions.series[2].data = serviceNums;
        serviceOptions.series[3].data = makeNums;
        serviceOptions.series[4].data = completeNums;
        serviceOptions.series[5].data = settlementNums;

        this.serviceInstance = this.$echarts.initCharts(
          this.totalInstance,
          this.$refs["service-charts"],
          serviceOptions
        );
      });
    },
    handleChangeDate() {
      this.installOrderData();
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
